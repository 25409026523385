#formOwnScroll {
  max-height: 100%;
  overflow-y: auto;
}

#topButtons {
  margin: 10px 0;
  text-align: center;
}

#topButtons * {
  margin-right: 10px;
}

#commitForm {
  text-align: center;
}

.commandRow {
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
}

.secondRowRemove,
.languageObj {
  display: flex;
  margin-bottom: 45px;
  margin-left: 49px;
  justify-content: center;
}

.secondRow,
.languageFields {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.descriptionField {
  margin-bottom: 10px !important;
}

.commandNameField {
  margin-right: 10px !important;
}

button.remove {
  margin-bottom: 70px;
}
