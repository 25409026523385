.app .contents {
  max-width: 100%;
  height: calc(100vh - 140px);
}

.app .contents .mode {
  padding: 10px 0;
}

.app .contents .code {
  background: #f5f5f5;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiTabs-indicator {
  background-color: inherit !important;
}

.MuiTab-textColorPrimary.Mui-disabled {
  color: white !important;
  border-radius: 7px;
  background-color: #2196f3;
}
