@media only screen and (min-width: 60px) {
  .drawerContent {
    display: grid;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 70px;
  }
  .drawerFlex {
    display: inline-flex;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 70px;
  }
  .forgotPassword {
    text-align: center;
    padding-top: 70px;
  }
  .signUp {
    display: block;
    text-align: center;
    padding-top: 70px;
  }
}

@media only screen and (max-width: 600px) {
  .drawerContent {
    padding-right: 20px;
    padding-left: 20px;
  }
}
