#main {
  display: flex;
  flex-direction: column;
  margin: auto;
}

#tumla {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#tumlaLogo {
  max-height: 40px;
}

#robot {
  max-height: 200px;
  max-width: 200px;
}

h2 {
  font-family: fantasy;
}
